css

body {
  background-color: #eaf6ff; /* Change this value to the desired background color */
}
.lead-container {
  background-color: #65a8dc;
  padding: 20px;
  border-radius: 5px;
  
}
 h1 {
  color: #ece7e7;
  font-size: 48px;
  margin-bottom: 10px;
}

h4 {
  color: #ece7e7;
  font-size: 20px;
  margin-bottom: 50px;
}

h5 {
  color: #ece7e7;
  font-size: 13px;
  margin-bottom: 0px;
}
h2 {
  color: #ece7e7;
  font-size: 32px;
  margin-bottom: 40px;
}
 .grid-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* Adjust the number of columns here */
  gap: 20px;
  justify-items: center; /* Center the items horizontally */
}
 .grid-item {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  transition: transform 0.4s ease; /* Add a transition for hover effect */
}
 .grid-item:hover {
  transform: scale(1.05); /* Add a scale effect on hover */
}
 .owner-info {
  display: flex;
  
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  
}
 .owner-info a {
  color: blue;
  text-decoration: none;
}
 .owner-info a:hover {
  text-decoration: underline;
}
.owner-image {
    width: 175px; /* Adjust the width of the image here */
    height: 175px; /* Adjust the height of the image here */
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  
  .owner-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .owner-image:hover img {
    transform: scale(1.1); /* Adjust the scale value to control the zoom effect */
  }

  .credits {
    /* Add the following styles */
    margin-top: auto;
    background-color: #f5f5f5; /* Replace with your desired background color */
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
  }